import { eventSender } from 'Lib/event-sender/event-sender';
import { onScreen } from 'Lib/on-screen/on-screen';

function sendEventGA4($el, event) {
    eventSender.customDL({
        event: 'eksmo',
        eventAction: event,
        eventCategory: $('title').text().trim(),
        eventLabel: `Каталог книг${event === 'click' ? ` - ${$el.find('.catalog-list-icons__title').text()}` : ''}`,
    });
}

onScreen($('.catalog-list-icons'), ($el) => {
    sendEventGA4($el, 'view');
}, 0, 0, 0.8);

$('.catalog-list-icons__item').on('click', function () {
    sendEventGA4($(this), 'click');
});