import { oLike } from 'Lib/like/like';
import { eksmoBP } from 'Lib/bp/bp';
import { eventSender } from 'Lib/event-sender/event-sender';
import { getW } from 'Lib/w/w';
import { onScreen } from 'Lib/on-screen/on-screen';
import { speed } from 'Lib/speed/speed';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import 'Vendor/owlcarousel/owl.carousel.min';
import { isLocalhost } from 'Lib/is-localhost/is-localhost';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';
import { formatPrice } from 'Lib/format-price/format-price';

const $body = $('body');
const sParent = '.books-slider';
const sSlider = '.books-slider__slider';
const cSliderOn = 'owl-carousel';
const cIssetSidebar = 'books-slider_isset-sidebar';
const cJArticle = 'books-slider_j-article';
const sBookSliderItem = '.books-slider__item';
const sBookSliderLink = '.books-slider__link';
const cSmall = 'books-slider_small-books';
const cOtherEditions = 'books-slider_other-editions';
const cTogetherCheaper = 'books-slider_together-cheaper';
const cIssetDots = 'books-slider_dots';
const sImageLoader = '.books-slider__img-loader';
let lastStateIsMobileScreen = isMobileScreen();
const cPostLoad = 'books-slider_post-load';
const cPostLoadBefore = 'books-slider_post-load-before';
const offsetPostLoadBefore = 2000;
const cPostLoaded = 'books-slider_post-loaded';
const $postLoad = $(`.${cPostLoad}`);
const sItemSkeleton = '.books-slider__item-skeleton';
const sItemTpl = '.books-slider__item-tpl';
const localhostPathVerstka = '/eksmo/_verstka';

function isMobileScreen() {
    return (getW() < eksmoBP.smMin);
}

function initLoaderImages($curSlider) {
    const $parent = $curSlider.closest(sParent);

    onScreen($parent.find('.book__image'), ($el) => {
        loadSrcOrBg($el.find(sImageLoader));
    }, $parent.hasClass(cPostLoadBefore) ? offsetPostLoadBefore : 0);
}

function initSenderAnalytics($curSlider) {
    onScreen($curSlider.find(sBookSliderLink), ($link) => {
        const $book = $link.closest(sBookSliderItem);

        if ($book.length && !$book.closest('.owl-item:not(.active)').length) {
            const id = $book.attr('data-id');
            const price = Number(formatPrice($book.find('.book__price-cur').text()));
            const oldPrice = Number(formatPrice($book.find('.book__price-old').text()));
            const isPreorder = Number($book.find('.btn-shop').first().text().trim().includes('Предзаказ'));

            eventSender.impressionsGA4({
                price,
                name: $book.hasClass('book_oe') ? $('.book-page__card-title').first().text().trim() : $book.find('.book__name').first().text().trim(),
                id,
                oldPrice,
                isPreorder,
                discountPrice: oldPrice && price ? oldPrice - price : null,
                brand: $book.attr('data-brand'),
                author: $book.attr('data-author') || $book.find('.book__author').text().trim(),
                firstCategory: $book.attr('data-first-category'),
                lastCategory: $book.attr('data-last-category'),
                type: $book.find('.book__format').text(),
                list: $book.closest('[data-ga-ecommerce-name]').attr('data-ga-ecommerce-name'),
                index: $book.attr('data-pos'),
            });
        }
    }, 0, 0, 0.8);
}

function setSliders() {
    $(sSlider).each(function () {
        setSlider($(this));
    });
}

function setSlider($curSlider) {
    const $parent = $curSlider.closest(sParent);

    if ($parent.length && (!$parent.hasClass(cPostLoad) || $parent.hasClass(cPostLoaded))) {
        if (isMobileScreen()) {
            $curSlider.trigger('destroy.owl.carousel').removeClass(cSliderOn);
            initLoaderImages($curSlider);
            initSenderAnalytics($curSlider);
        } else {
            $curSlider.find('.books-slider__item_arrow').remove();

            if ($curSlider.find(sBookSliderItem).length) {
                const issetSidebar = $parent.hasClass(cIssetSidebar);
                const isJArticle = $parent.hasClass(cJArticle);
                const isSmall = $parent.hasClass(cSmall);
                const isOtherEditions = $parent.hasClass(cOtherEditions);
                const isTogetherCheaper = $parent.hasClass('books-slider_together-cheaper');
                const issetDots = $parent.hasClass(cIssetDots);
                const isBigContainer = $parent.closest('.container_big');
                const oRes = {
                    0: { items: 4, slideBy: 4 },
                    880: { items: 3, slideBy: 3 },
                    992: { items: 4, slideBy: 4 },
                };
                let cDots = 'books-slider__dots';
                const additionClassArrow = $parent.hasClass('books-slider_arrow-small') ? ' arrow_small' : '';

                cDots += $(sSlider).index($curSlider);
                $parent.find('.books-slider__dots').addClass(cDots);

                if (issetSidebar && !isBigContainer.length) {
                    $.extend(oRes, { 992: { items: 4, slideBy: 4 } });
                } else if (isJArticle) {
                    $.extend(oRes, { 880: { items: 4, slideBy: 4 }, 992: { items: 3, slideBy: 3 } });
                } else if (isOtherEditions) {
                    $.extend(oRes, { 0: { items: 6, slideBy: 6 }, 880: { items: 7, slideBy: 7 }, 992: { items: 3, slideBy: 3 } });
                } else if (isTogetherCheaper) {
                    $.extend(oRes, {
                        0: { items: 6, slideBy: 6 },
                        770: { items: 3, slideBy: 3 },
                        992: { items: 3, slideBy: 3 } });
                } else if (isSmall) {
                    $.extend(oRes, { 0: { items: 5, slideBy: 5 }, 880: { items: 6, slideBy: 6 }, 1024: { items: 7, slideBy: 7 } });
                } else if ($parent.hasClass('books-slider_top')) {
                    $.extend(oRes, {
                        992: { items: 3, slideBy: 3 } });
                } else if (isBigContainer.length) { // Для нового широкого контейнера
                    $.extend(oRes, {
                        1024: { items: 4, slideBy: 4 },
                        1200: { items: 5, slideBy: 5 },
                        1600: { items: 6, slideBy: 6 },
                    });
                } else if ($parent.hasClass('books-slider_main')) { // Для нового широкого контейнера
                    $.extend(oRes,
                        { 880: { items: 4, slideBy: 4 } },
                        { 1024: { items: 5, slideBy: 5 } });
                }

                const oParams = {
                    smartSpeed: speed,
                    dots: false,
                    nav: true,
                    navClass: [
                        `books-slider__prev arrow arrow_prev arrow_shadow${additionClassArrow}`,
                        `books-slider__next arrow arrow_next arrow_shadow${additionClassArrow}`,
                    ],
                    navText: ['', ''],
                    responsive: oRes,
                    onInitialized(el) {
                        initLoaderImages($(el.target));
                        initSenderAnalytics($(el.target));

                        if (typeof oLike !== 'undefined') {
                            oLike.init('.js-book-like');
                        }
                    },
                };

                if (issetDots) {
                    oParams.dots = true;
                    oParams.dotsContainer = `.${cDots}.dots`;
                    oParams.dotClass = 'dots__item';
                }

                $curSlider.trigger('destroy.owl.carousel')
                    .removeClass(cSliderOn)
                    .addClass(cSliderOn)
                    .owlCarousel(oParams);
            }
        }
    }
}

async function getPostLoadData($thisSlider) {
    const attrNomcodes = $thisSlider.attr('data-post-load');
    const result = {
        'type': 'default',
        'nomcodes': [],
    };

    if (!result.nomcodes.length && isNoEmptyString(attrNomcodes)) {
        result.nomcodes = attrNomcodes.split(',');
    }

    return result;
}

async function loadPostLoadSlider($thisSlider) {
    if ($thisSlider.length && !$thisSlider.hasClass(cPostLoaded)) {
        const data = await getPostLoadData($thisSlider);
        const $thisSliderItemTpl = $thisSlider.find(sItemTpl).first();
        let tpl = $thisSliderItemTpl.html();
        const $thisSliderCont = $thisSlider.find(sSlider).first();

        if ($thisSliderCont.length && data.nomcodes.length) {
            const urls = data.nomcodes.map((nomcode) => isLocalhost() ? `${localhostPathVerstka}/data/book/${nomcode}.json` : `https://${window.location.hostname}/bitrix/v2_html_prices/${nomcode.slice(-3)}/${nomcode}_json.json`);
            const promiseUrls = await Promise.all(urls.map((u) => fetch(u)));
            const dataBooks = await Promise.all(promiseUrls.map((res) => res.ok ? res.json() : false));
            const htmlItems = dataBooks.map((item, pos) => {
                if (item) {
                    let htmlAuthor = '';
                    let htmlFormat = '';
                    let htmlPrice = '';
                    let htmlBtn = '';
                    const author = item.author;
                    const nomcode = item.nomcode;
                    const guid = item.guid;
                    const age18 = item.age18 ? ' popup18 popup18_active' : '';
                    const customImage = item?.customImage;
                    const price = parseInt(item.price.price);
                    const oldPrice = parseInt(item.price.oldprice);
                    const descShort = item.desc_short ? `<div class="book__desc-short">${item.desc_short}</div>` : '';
                    const sale = (!Number.isNaN(price) && !Number.isNaN(oldPrice) && price && oldPrice && price < oldPrice)
                        ? 100 - Math.round((price / oldPrice) * 100)
                        : 0;
                    let charity = sale ? `<a href="${item.url}" class="book__shield">-${sale}%</a>` : '';
                    const isPreorder = (item.price.preorder === 'Предзаказ');
                    // HTML блока лайка
                    const htmlLike = `
<div class="book__like">
    <div class="like js-book-like" data-type="products" data-id="${guid}" data-bdl-id="${nomcode}">
        <svg class="like__svg like__svg_n"><use xlink:href="#like"></use></svg>
        <svg class="like__svg like__svg_y"><use xlink:href="#like-color"></use></svg>
    </div>
</div>
                    `;
                    const btnReview = $thisSlider.hasClass('books-slider_btn-review');

                    charity += item.charity ? `<a href="${item.charity}" class="book__shield book__shield_charity${sale ? ' book__shield_charity-with-sale' : ''}"></a>` : '';

                    // HTML блока автор
                    if (isNoEmptyString(item.author)) {
                        htmlAuthor = `
                                        <div class="book__author">${item.author}</div>
                                    `;
                    }

                    // HTML блока формат книги: бумага (твёрдый переплёт, мягкая обложка), электронная, аудио
                    if (isNoEmptyString(item.format)) {
                        htmlFormat = `
                                        <div class="book__format">${item.format}</div>
                                    `;
                    }
                    //

                    // HTML блока с ценой
                    if (!Number.isNaN(price) && price) {
                        htmlPrice = `
                                        <div class="book__price-block">
                                            <div class="book__price">
                                                <span class="book__price-cur" data-price="${oldPrice || price}" data-price-sale="${price || ''}">${oldPrice && $thisSlider.hasClass(cTogetherCheaper) ? oldPrice : price} <span class="book__currency">${item.price.currency}</span></span>
                                                ${sale ? `<span class="book__price-old">${oldPrice} <span class="book__currency">${item.price.currency}</span></span>` : ``}
                                            </div>
                                        </div>
                                    `;
                    } else {
                        htmlPrice = `
                            <svg class="book__price-icon-na"><use xlink:href="#na"></use></svg>
                          `;
                    }

                    // HTML блока с кнопкой
                    if ($thisSlider.attr('data-collection-id')) {
                        htmlBtn = `<div class="book__btns book__btns_w-full"><div class="btn btn_border btn_small book__btn-add-collection">В подборку</div></div>`;
                    } else if (btnReview) {
                        htmlBtn = `<div class="book__btns book__btns_w-full"><a href="${item.url}#reviews" class="btn btn_border btn_small book__btn-review">Оставить отзыв</a></div>`;
                    } else if (item.price.shop) {
                        if (age18) {
                            htmlBtn = `
                                <div class="book__btns popup18__content18">
                                    <div class="btn-shop btn-shop_disabled${isPreorder ? ' btn-shop_gray' : ''}">
                                      Контент 18+
                                    </div>
                                </div>
                            `;
                        }

                        if (isPreorder) { // бук24, предзаказ (https://eksmo.ru/bitrix/v2_html_prices/949/ITD000000001092949_json.json)
                            htmlBtn += `
                                <div class="book__btns${age18 ? ' popup18__content-no18' : ''}">
                                    <div class="btn-shop btn-shop_gray btn-shop_b24"
                                        data-price="${price}"
                                        data-id="${nomcode}">
                                    Предзаказ
                                    </div>
                                </div>
                            `;
                        } else if (item.price.outer_href_book) {
                            htmlBtn += `
                                <div class="book__btns${age18 ? ' popup18__content-no18' : ''}">
                                    <a class="btn-shop" href="${item.price.buy_link}" target="_blank" rel="nofollow noopener"><span class="btn-shop__inner-text-hide">Купить&nbsp;</span>${item.price.shop_name ? `На ${item.price.shop_name}` : ''}</a>
                                </div>
                            `;
                        } else { // можно купить (https://eksmo.ru/bitrix/v2_html_prices/949/ITD000000000187735_json.json)
                            htmlBtn += `
                                <div class="book__btns${age18 ? ' popup18__content-no18' : ''}">
                                    <div
                                        class="btn-shop btn-shop_b24"
                                        data-book24-widget="${item.price.product_id}"
                                        data-price="${price}"
                                        data-id="${nomcode}"
                                        data-guid="${guid}"
                                        data-book24-widget-basket-button-title="Купить"
                                    >
                                        <svg class="btn-shop__icon"><use xlink:href="#cart"></use></svg>
                                        В корзину
                                    </div>
                                </div>
                            `;
                        }
                    } else { // нет в наличии (https://eksmo.ru/bitrix/v2_html_prices/292/ITD000000000086292_json.json)

                    }

                    if (customImage) {
                        tpl = tpl.replace(/https:\/\/cdn.eksmo.ru\/v2\/#nomcode#\/COVER\/cover1__w220.webp, https:\/\/cdn.eksmo.ru\/v2\/#nomcode#\/COVER\/cover1__w410.webp 2x/g, customImage);
                        tpl = tpl.replace(/https:\/\/cdn.eksmo.ru\/v2\/#nomcode#\/COVER\/cover1__w220.jpg/g, customImage);
                        tpl = tpl.replace(/https:\/\/cdn.eksmo.ru\/v2\/#nomcode#\/COVER\/cover1__w410.jpg/g, customImage);
                    }

                    return tpl
                        .replace(/#book24-widget#/g, item.price.product_id)
                        .replace(/#nomcode#/g, nomcode)
                        .replace(/#pos#/g, pos + 1)
                        .replace(/#brand#/g, item.brand)
                        .replace(/#category#/g, item.category)
                        .replace(/#categoryFirst#/g, item.first_category ? item.first_category : '')
                        .replace(/#available#/g, item.available)
                        .replace(/#url#/g, btnReview ? `${item.url}#reviews` : item.url)
                        .replace(/#name#/g, $thisSlider.hasClass(cOtherEditions) ? '' : item.name)
                        .replace(/#html-author#/g, htmlAuthor)
                        .replace(/#author#/g, author)
                        .replace(/#html-format#/g, htmlFormat)
                        .replace(/#html-price#/g, htmlPrice)
                        .replace(/#html-btn#/g, htmlBtn)
                        .replace(/#html-like#/g, htmlLike)
                        .replace(/#class#/g, age18 || '')
                        .replace(/#classImg#/g, age18 ? ' popup18__img' : '')
                        .replace(/#preorder#/g, item.releases_info ? `<div class="book__alert-preorder">${item.releases_info}</div>` : '')
                        .replace(/#charity#/g, charity || '')
                        .replace(/#desc-short#/g, descShort || '');
                }

                return '';
            });

            $thisSliderItemTpl.remove();
            $thisSliderCont.find(sItemSkeleton).remove();
            $thisSliderCont.prepend(htmlItems.join('\n'));
            $thisSlider.addClass(cPostLoaded);
            setSlider($thisSliderCont);
            $thisSlider.trigger('eksmoBooksSliderPostLoaded');

            if ($thisSlider.find('[data-dmp-event=click-this-book-in-other-editions]').length) { // VIS DMP
                document.dispatchEvent(new Event('otherEditionsLoaded.dmp.eksmo'));
            }
        }
    }

    setupButtonAddToBasket(window.settingsWl);
}

setSliders();

$body.on('eksmoResizeWidth', () => {
    if (isMobileScreen() !== lastStateIsMobileScreen) {
        setSliders();
        lastStateIsMobileScreen = isMobileScreen();
    }
});

$body.on('eksmoPagenavAjax', (e) => {
    setSlider($(e.target).find(sSlider));
});

$body.on('click', sBookSliderLink, function (e) {
    const $this = $(this);

    if ($this.closest('.owl-item:not(.active)').length) {
        e.preventDefault();
    }
});

// $postLoad
export function initPostload(element) {
    if (element.length) {
        element.each(function () {
            const $parent = $(this);

            onScreen($parent, loadPostLoadSlider, $parent.hasClass(cPostLoadBefore) ? offsetPostLoadBefore : 0);
        });
    }
}

initPostload($postLoad);