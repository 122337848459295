import { onScreen } from 'Lib/on-screen/on-screen';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import { eventSender } from 'Lib/event-sender/event-sender';

const $whatReadItem = $('.what-read__item');

function setBgImg() {
    onScreen($('.what-read__img-box'), loadSrcOrBg);
}

function sendEventGA4($el, event) {
    eventSender.customDL({
        event: 'eksmo',
        eventAction: event,
        eventCategory: $('title').text().trim(),
        eventLabel: `Что почитать. Выбор редакции - ${$el.find('.what-read__title').text()}`,
    });
}

onScreen($whatReadItem, ($el) => {
    sendEventGA4($el, 'view');
}, 0, 0, 0.8);

$whatReadItem.on('click', function () {
    sendEventGA4($(this), 'click');
});

setBgImg();
$('body').on('eksmoResizeWidth', setBgImg);