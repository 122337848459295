import { eksmoBP } from 'Lib/bp/bp';
import { speed } from 'Lib/speed/speed';
import { getW } from 'Lib/w/w';
import { onScreen } from 'Lib/on-screen/on-screen';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import { eventSender } from 'Lib/event-sender/event-sender';
import 'Vendor/owlcarousel/owl.carousel.min';

const $block = $('.brands:not(.brands_simple)');
const cSliderOn = 'owl-carousel';

function initLoaderImages($el) {
    onScreen($el, loadSrcOrBg);
}

function setSliderText(i) {
    let index = parseInt(i);
    const $text = $('.brands__slider-text');
    const cTextActive = 'brands__slider-text_active';

    if (index < 0) {
        index = 0;
    }

    $text.removeClass(cTextActive);
    $text.eq(index).addClass(cTextActive);
}

function sendEventGA4($el, event) {
    eventSender.customDL({
        event: 'eksmo',
        eventAction: event,
        eventCategory: $('title').text().trim(),
        eventLabel: `Наши бренды${event === 'click' ? ` - ${$el.attr('href')}` : ''}`,
    });
}

if ($block.length) {
    if (getW() <= eksmoBP.xsMax) {
        $block.find('.brands__slider').each(function () {
            $(this).trigger('destroy.owl.carousel')
                .removeClass(cSliderOn)
                .addClass(cSliderOn)
                .owlCarousel({
                    items: 1,
                    margin: 15,
                    autoWidth: true,
                    smartSpeed: speed,
                    navText: ['', ''],
                    onInitialized(e) {
                        setSliderText(e.page.index);
                        initLoaderImages($(e.target).find('.brands__item_loader'));
                    },
                })
                .on('translated.owl.carousel', (e) => {
                    setSliderText(e.page.index);
                });
        });
    } else {
        initLoaderImages($block.find('.brands__dlogos-link'));
    }
}

onScreen($('.brands'), ($el) => {
    sendEventGA4($el, 'view');
}, 0, 0, 0.8);

$('.brands__dlogos-link').on('click', function () {
    sendEventGA4($(this), 'click');
});