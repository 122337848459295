import { onScreen } from 'Lib/on-screen/on-screen';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import { getW } from 'Lib/w/w';
import { eksmoBP } from 'Lib/bp/bp';
import 'Vendor/owlcarousel/owl.carousel.min';

const cOwl = 'owl-carousel';
const $salesMain = $('.sales-main');

function setSlider() {
    if (getW() > eksmoBP.xsMax) {
        $salesMain.trigger('destroy.owl.carousel')
            .removeClass(cOwl)
            .addClass(cOwl)
            .owlCarousel({
                items: 2,
                slideBy: 2,
                navClass: ['sales-main__arrow-prev arrow arrow_prev arrow_shadow', 'sales-main__arrow-next arrow arrow_next arrow_shadow'],
                navText: ['', ''],
                margin: 40,
                nav: true,
                onInitialized(e) {
                    onScreen($(e.target).find('.sales-main__item-link'), loadSrcOrBg);
                },
            });
    } else {
        $salesMain.trigger('destroy.owl.carousel');
    }
}

setSlider();
$('body').on('eksmoResizeWidth', setSlider);
onScreen($('.sales-main__item-link'), loadSrcOrBg);