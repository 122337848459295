import 'COMMON';
import { svgSpriteLoader } from 'Lib/svg-sprite-loader/svg-sprite-loader';
import { oLike } from 'Lib/like/like';
import { onScreen } from 'Lib/on-screen/on-screen';
import 'Bem/site/tabs/tabs';
import 'Bem/site/main-slider2/main-slider2';
import 'Bem/site/triple-slider/triple-slider';
import 'Bem/site/books-slider/books-slider';
import 'Bem/site/brands/brands';
import 'Bem/site/meetings/meetings';
import 'Bem/site/collections-slider/collections-slider';
import 'Bem/site/collection/collection';
import 'Bem/site/book/book';
import 'Bem/site/bg/bg';
import 'Bem/site/promo/promo';
import 'Bem/site/sales-main/sales-main';
import 'Bem/site/img-loader/img-loader';
import 'Bem/site/popup-subscribe/popup-subscribe';
import 'Bem/site/buy-now/buy-now';
import 'Bem/site/journal-articles/journal-articles';
import 'Bem/site/catalog-list-icons/catalog-list-icons';
import 'Bem/site/what-read/what-read';
import 'Lib/js-anchor-link/js-anchor-link';
import 'Bem/site/releases/releases';

svgSpriteLoader('/site/main/svg/sprite.svg?v=20190822');
oLike.init('.js-book-like, .js-book-article-like, .js-article-like');

onScreen($('.js-bg_dynamic-bg-valentine'), ($el) => {
    $el.addClass('bg_dynamic-bg bg_dynamic-bg-valentine');
}, 100);