import { onScreen } from 'Lib/on-screen/on-screen';
import { eventSender } from 'Lib/event-sender/event-sender';
import { formatPrice } from 'Lib/format-price/format-price';

const $releasesItem = $('.releases__item');

function setReleasesItemData($book, type) {
    const price = Number(formatPrice($book.attr('.data-price')));
    const oldPrice = Number(formatPrice($book.attr('data-old-price')));
    const isPreorder = Number($book.find('.btn-shop').first().text().trim().includes('Предзаказ'));
    const obj = {
        price,
        name: $book.find('.releases__title').text().trim(),
        id: $book.attr('data-id'),
        oldPrice,
        isPreorder,
        discountPrice: oldPrice && price ? oldPrice - price : null,
        brand: $book.attr('data-brand'),
        author: $book.find('.releases__author').text().trim(),
        firstCategory: $book.attr('data-first-category'),
        lastCategory: $book.attr('data-last-category'),
        type: 'Бумажная',
        list: $book.attr('data-ga-ecommerce-name'),
        index: $book.attr('data-pos'),
    };

    if (type === 'click') {
        eventSender.clickGA4(obj);
    } else {
        eventSender.impressionsGA4(obj);
    }
}

onScreen($releasesItem, ($book) => {
    setReleasesItemData($book);
}, 0, 0, 0.8);

$releasesItem.on('click', function () {
    setReleasesItemData($(this), 'click');
});